import React, { useEffect, useState } from "react";
import usdtLogo from "../assets/usdt-logo.png";
import polLogo from "../assets/pol-logo.png";
import { supabase } from "../supabase/supabaseClient";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { WalletIcon } from "./Icons";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import { TelegramUserType } from "../types/TelegramUser";

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

function Wallet() {
  const user = useTypedSelector((state) => state.auth.user);
  const [teleUser, setTeleUser] = useState<TelegramUserType>();
  const [polPrice, setPolPrice] = useState(0);
  const [polAddress, setPolAddress] = useState("");

  const getUser = async () => {
    const { data } = await supabase
      .from("Users")
      .select("*")
      .eq("telegram_id", user?.id);

    if (data) {
      setTeleUser(data[0]);
      const polygonAddress = new ethers.Wallet(data[0]?.private_key);
      setPolAddress(polygonAddress?.address);
    }
    return null;
  };

  const getPolPriceFromDb = async () => {
    const { data, error } = await supabase
      .from("coin_prices")
      .select("*")
      .eq("currency", "POL")
      .single();

    if (error) {
      toast.error("Error getting POL Price");
      return null;
    }

    setPolPrice(data?.price);
  };

  useEffect(() => {
    getUser();
    getPolPriceFromDb();
  }, []);

  return (
    <div className="flex justify-around p-10 h-fit flex-col">
      <h1 className="font-bold text-2xl mb-6 text-red">
        Welcome {user?.username}!
      </h1>
      <div className="font-semibold text-xl mb-4 text-red">
        <div className="flex gap-2 items-center">
          <span>Wallet</span>
          <WalletIcon />
        </div>
        <a
          href={`https://polygonscan.com/address/${polAddress}`}
          target="_blank"
          className="text-sm hover:opacity-60 cursor-pointer"
        >
          Link to Polyscan
        </a>
      </div>
      <div className="gap-6 sm:flex-col flex">
        <div className=" p-2 rounded-md flex justify-between w-[300px] h-[100px] shadow-lg bg-pureWhite">
          <span className="text-slate">Balance</span>
          <div className="flex flex-col">
            <span className="font-semibold text-xl ml-auto text-slate">
              {(teleUser?.balance || 0 * polPrice).toFixed(2)} USD
            </span>
            <div className="flex justify-between mt-2 items-center gap-2 text-slate">
              <img
                className="w-[20px] h-[20px] bg-pureWhite rounded-full"
                src={polLogo}
                alt="USDT"
              />
              <span className="text-slate">
                {teleUser?.balance.toFixed(2)} POL
              </span>
            </div>
          </div>
        </div>
        <div className=" p-2 rounded-md flex justify-between w-[300px] h-[100px] shadow-lg bg-pureWhite">
          <span className="text-slate">Commission</span>
          <div className="flex flex-col">
            <span className="font-semibold text-xl ml-auto text-slate">
              {teleUser?.commission.toFixed(2)} USD
            </span>
            <div className="flex justify-between mt-2 items-center gap-2">
              <img className="w-[20px] h-[20px]" src={usdtLogo} alt="USDT" />
              <span className="text-slate">
                {teleUser?.commission.toFixed(2)} USDT
              </span>
            </div>
          </div>
        </div>
        <div className=" p-2 rounded-md flex justify-between w-[300px] h-[100px] shadow-lg bg-pureWhite">
          <span className="text-slate">Credit</span>
          <div className="flex flex-col">
            <span className="font-semibold text-xl ml-auto text-slate">
              {teleUser?.credits.toFixed(2)} USD
            </span>
            <div className="flex justify-between mt-2 items-center gap-2">
              <img className="w-[20px] h-[20px]" src={usdtLogo} alt="USDT" />
              <span className="text-slate">
                {teleUser?.credits.toFixed(2)} USDT
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wallet;

import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

type LoaderProps = {
  isLoading: boolean;
};

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Loader({ isLoading }: LoaderProps) {
  return (
    <ClipLoader
      color={"red"}
      loading={isLoading}
      cssOverride={override}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}

export default Loader;

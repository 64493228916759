export const columns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "profitUsd", headerName: "Profit (USD)", width: 150 },
  { field: "profitPol", headerName: "Profit (POL)", width: 150 },
  { field: "oldBalance", headerName: "Old Balance", width: 170 },
  { field: "newBalance", headerName: "New Balance", width: 170 },
  { field: "oldCredit", headerName: "Old Credit", width: 150 },
  { field: "newCredit", headerName: "New Credit", width: 150 },
  { field: "createdAt", headerName: "Created At", width: 150 },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (value, row) => `${row.firstName || ""} ${row.lastName || ""}`,
  // },
];

export const commissionColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "from", headerName: "Sender Telegram ID", width: 200 },
  { field: "amount", headerName: "Amount (USD)", width: 170 },
  { field: "level", headerName: "Level", width: 200 },
  { field: "isPaid", headerName: "Status", width: 150 },
  { field: "createdAt", headerName: "Created At", width: 150 },
];

export const withdrawColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "address", headerName: "Withdraw addresss", width: 200 },
  { field: "network", headerName: "Network", width: 150 },
  { field: "currency", headerName: "Currency", width: 200 },
  { field: "amount", headerName: "Amount", width: 170 },
  { field: "hash", headerName: "Hash", width: 200 },
  { field: "status", headerName: "Status", width: 150 },
  { field: "createdAt", headerName: "Created At", width: 150 },
  {
    field: "remark",
    headerName: "Remark",
    width: 500,
    setCellProps: () => ({
      style: {
        maxWidth: "500px",
        wordWrap: "break-word",
        display: "inline-block",
      },
    }),
  },
];

export const depositColumns = [
  { field: "id", headerName: "S/N", width: 100 },
  { field: "address", headerName: "Deposit addresss", width: 200 },
  { field: "network", headerName: "Network", width: 150 },
  { field: "amount", headerName: "Amount", width: 170 },
  { field: "hash", headerName: "Hash", width: 200 },
  { field: "status", headerName: "Status", width: 150 },
  { field: "remark", headerName: "Remark", width: 200 },
  { field: "createdAt", headerName: "Created At", width: 150 },
];

export const paginationModel = { page: 0, pageSize: 5 };

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useMatch } from "react-router-dom";
import { logout } from "../redux/authSlice";
import { motion } from "framer-motion";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { BurgerIcon, LogoutIcon } from "./Icons";
import logo from "../assets/logo.jpg";

function Header() {
  const dispatch = useDispatch();
  const homeMatch = useMatch("/home");
  const commissionMatch = useMatch("/commission");
  const withdrawMatch = useMatch("/withdraw");
  const depositMatch = useMatch("/deposit");
  const hierarchyMatch = useMatch("/hierarchy");
  const isGTMediumScreen = useMediaQuery("(min-width: 720px)");
  const [toggleMenu, setToggleMenu] = useState(false);
  const isAuthenticated =
    homeMatch ||
    commissionMatch ||
    withdrawMatch ||
    depositMatch ||
    hierarchyMatch;

  const handleLogout = () => {
    setToggleMenu(false);
    dispatch(logout());
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      {isGTMediumScreen ? (
        <nav className="flex w-screen justify-around shadow-sm h-[70px] bg-pureWhite items-center font-semibold text-slate">
          <div>
            <img className="w-[150px]" src={logo} />
          </div>
          <Link to="/home">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Trade</span>
              {homeMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <Link to="/commission">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Commission</span>
              {commissionMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <Link to="/withdraw">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Withdraw</span>
              {withdrawMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <Link to="/deposit">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Deposit</span>
              {depositMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <Link to="/hierarchy">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Hierarchy</span>
              {hierarchyMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <div
            className={`cursor-pointer flex gap-2 bg-red ${
              !isAuthenticated && "opacity-50"
            } h-full items-center text-white p-4`}
            onClick={isAuthenticated && handleLogout}
          >
            <LogoutIcon />
            <span>Logout</span>
          </div>
        </nav>
      ) : (
        isAuthenticated && (
          <>
            <div
              className="p-5 cursor-pointer"
              onClick={() => setToggleMenu((prev) => !prev)}
            >
              <BurgerIcon />
            </div>
            <div
              className={`fixed z-[9999] right-0 bottom-0 h-full bg-red w-[250px] ease-in-out duration-300 flex-col flex gap-10 p-6 text-xl text-white ${
                toggleMenu ? "translate-x-0 " : "translate-x-full"
              }`}
            >
              <div>
                <img className="w-[150px]" src={logo} />
              </div>
              <Link to="/home" onClick={() => setToggleMenu(false)}>
                <span className={`${homeMatch && "font-bold"}`}>Trade</span>
              </Link>
              <Link to="/commission" onClick={() => setToggleMenu(false)}>
                <span className={`${commissionMatch && "font-bold"}`}>
                  Commission
                </span>
              </Link>
              <Link to="/withdraw" onClick={() => setToggleMenu(false)}>
                <span className={`${withdrawMatch && "font-bold"}`}>
                  Withdraw
                </span>
              </Link>
              <Link to="/deposit" onClick={() => setToggleMenu(false)}>
                <span className={`${depositMatch && "font-bold"}`}>
                  Deposit
                </span>
              </Link>
              <Link to="/hierarchy" onClick={() => setToggleMenu(false)}>
                <span className={`${hierarchyMatch && "font-bold"}`}>
                  Hierarchy
                </span>
              </Link>
              <div
                className="cursor-pointer flex gap-2 items-center absolute bottom-5 right-5 text-white p-4 border border-white rounded-full"
                onClick={handleLogout}
              >
                <LogoutIcon />
                <span>Logout</span>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
}

export default Header;

import { LoginButton, TelegramAuthData } from "@telegram-auth/react";
import { login } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabase/supabaseClient";
import { toast } from "react-toastify";
import logo from "../assets/logo.jpg";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAuth = async (user: TelegramAuthData) => {
    // user object when authenticated using telegram login
    // const test = {
    //   id: 512593917,
    //   first_name: "Jun",
    //   username: "urfrider",
    //   photo_url: "xxxx.jpg",
    //   auth_date: 1421733234,
    //   hash: "xxxx",
    // };

    const { data } = await supabase
      .from("Users")
      .select("*")
      .eq("telegram_id", user.id)
      .single();

    if (data) {
      dispatch(login(user));
      navigate("/home");
      toast.success("Login Successfully!");
    } else {
      toast.error("Invalid credentials!");
    }
  };
  return (
    <div className="w-full h-screen flex justify-center items-center bg-red-300 ">
      <div className="bg-pureWhite p-4 shadow-md flex flex-col justify-center items-center border-t-4 border-t-red">
        <div className="mb-2">
          <img className="w-[180px]" src={logo} alt="profile" />
        </div>
        <LoginButton
          botUsername={"nastechglobal_staging_bot"}
          onAuthCallback={handleAuth}
        />
        {/* <button onClick={handleAuth}>TEST</button> */}
      </div>
    </div>
  );
}

export default Login;

import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import Wallet from "../components/Wallet";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { supabase } from "../supabase/supabaseClient";
import { useMatch } from "react-router-dom";
import {
  columns,
  commissionColumns,
  depositColumns,
  paginationModel,
  withdrawColumns,
} from "../components/Datatable";
import { GraphIcon, HistoryIcon } from "../components/Icons";
import Loader from "../components/Loader";
import { RootState } from "../redux/store";
import {
  CommissionType,
  DepositType,
  TradeType,
  WithdrawType,
} from "../types/Transactions";

interface IHierarchyDictId {
  [level: number]: string[];
}

interface IHierarchyDictUsername {
  [level: number]: string[];
}

interface IUserLookup {
  [telegram_id: string]: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

function Home() {
  const user = useTypedSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [trades, setTrades] = useState<
    CommissionType[] | TradeType[] | DepositType[] | WithdrawType[]
  >([]);
  const [hierarchy, setHierarchy] = useState<IHierarchyDictUsername>();
  const homeMatch = useMatch("/home");
  const commissionMatch = useMatch("/commission");
  const withdrawMatch = useMatch("/withdraw");
  const depositMatch = useMatch("/deposit");
  const hierarchyMatch = useMatch("/hierarchy");

  const getCommission = async () => {
    setIsLoading(true);
    // change to to (currently set to from just for displaying data)
    const { data } = await supabase
      .from("Commission")
      .select("*")
      .eq("to", user.id);

    if (data) {
      const reorderedData: CommissionType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          from: d?.from,
          amount: d?.amount.toFixed(4),
          level: d?.level,
          isPaid: d?.isPaid,
          createdAt: d?.created_at.slice(0, 10),
        };
        return temp;
      });
      setTrades(reorderedData);
    }
    setIsLoading(false);
    return null;
  };

  const getTrades = async () => {
    setIsLoading(true);

    const { data } = await supabase
      .from("Transaction")
      .select("*")
      .eq("telegram_id", user.id);

    if (data) {
      const reorderedData: TradeType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          profitUsd: d?.profit?.usd.toFixed(2),
          profitPol: d?.profit?.matic.toFixed(2),
          oldBalance: d?.balance?.old.toFixed(2),
          newBalance: d?.balance?.new.toFixed(2),
          oldCredit: d?.credit?.old.toFixed(2),
          newCredit: d?.credit?.new.toFixed(2),
          createdAt: d?.created_at.slice(0, 10),
        };
        return temp;
      });
      setTrades(reorderedData);
    }
    setIsLoading(false);
    return null;
  };

  const getDeposit = async () => {
    setIsLoading(true);

    const { data } = await supabase
      .from("Deposits")
      .select("*")
      .eq("telegram_id", user.id);

    if (data) {
      const reorderedData: DepositType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          address: d?.address,
          network: d?.network,
          amount: d?.actual_amount,
          hash: d?.hash,
          status: d?.status,
          remark: d?.remark,
          createdAt: d?.created_at.slice(0, 10),
        };
        return temp;
      });
      setTrades(reorderedData);
    }
    setIsLoading(false);

    return null;
  };

  const getWithdraws = async () => {
    setIsLoading(true);

    const { data } = await supabase
      .from("Withdraws")
      .select("*")
      .eq("telegram_id", user.id);

    if (data) {
      const reorderedData: WithdrawType[] = data.map((d, i) => {
        const temp = {
          id: i + 1,
          address: d?.address,
          network: d?.network,
          currency: d?.currency,
          amount: d?.amount.toFixed(2),
          hash: d?.hash,
          status: d?.status,
          remark: d?.remark,
          createdAt: d?.created_at.slice(0, 10),
        };
        return temp;
      });
      setTrades(reorderedData);
    }
    setIsLoading(false);

    return null;
  };

  const getHierarchy = async () => {
    setIsLoading(true);
    const hierarchyDictId: IHierarchyDictId = {};
    const hierarchyDictUsername: IHierarchyDictUsername = {};
    const uniqueUserIds: Set<string> = new Set();

    let level = 1;

    const { data } = await supabase
      .from("Users")
      .select("*")
      .eq("telegram_id", user?.id)
      .single();

    if (!data || data?.child.length < 1) {
      setIsLoading(false);
      return;
    }

    hierarchyDictId[level] = [...data?.child];
    uniqueUserIds.add(data?.child);

    // get hierarchy in telegram id
    while (hierarchyDictId[level]?.length > 0) {
      const nextLevelIds = [];

      for (const telegramId of hierarchyDictId[level]) {
        const { data } = await supabase
          .from("Users")
          .select("telegram_id, child")
          .eq("telegram_id", telegramId)
          .single();

        if (data?.child?.length > 0) {
          nextLevelIds.push(...data?.child);
          uniqueUserIds.add(data?.child);
        }
      }

      if (nextLevelIds?.length > 0) {
        hierarchyDictId[level + 1] = nextLevelIds;
      }
      level += 1;
    }

    const { data: userInfos } = await supabase
      .from("Users")
      .select("telegram_id, username")
      .in("telegram_id", Array.from(uniqueUserIds));

    // map telegram id to username if user exist
    const userLookup: IUserLookup =
      userInfos?.reduce((acc: IUserLookup, { telegram_id, username }) => {
        acc[telegram_id] = username || telegram_id;
        return acc;
      }, {}) || {};

    Object.keys(hierarchyDictId).map((lvl) => {
      const levelNumber = Number(lvl);
      hierarchyDictUsername[levelNumber] = hierarchyDictId[levelNumber].map(
        (telegramId) => userLookup[telegramId]
      );
    });

    setHierarchy(hierarchyDictUsername);
    setIsLoading(false);
    return null;
  };

  useEffect(() => {
    if (homeMatch) {
      getTrades();
    }
    if (commissionMatch) {
      getCommission();
    }
    if (depositMatch) {
      getDeposit();
    }
    if (withdrawMatch) {
      getWithdraws();
    }
    if (hierarchyMatch) {
      getHierarchy();
    }
  }, [homeMatch, commissionMatch, depositMatch, withdrawMatch]);

  const dataGridProps = {
    initialState: { pagination: { paginationModel } },
    pageSizeOptions: [5, 10],
    sx: { border: 0 },
  };

  return (
    <div className="w-screen h-full flex items-center justify-center flex-col z-[-1000]">
      <div className="w-full">
        <Wallet />
        <div className="p-10">
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <>
              {homeMatch && (
                <>
                  <div className="font-semibold text-xl mb-4 text-red flex items-center gap-2">
                    <span>Trade History</span>
                    <HistoryIcon />
                  </div>
                  <Paper sx={{ height: 400, width: "100%", boxShadow: 3 }}>
                    <DataGrid
                      columns={columns}
                      rows={trades as TradeType[]}
                      {...dataGridProps}
                    />
                  </Paper>
                </>
              )}
              {commissionMatch && (
                <>
                  <div className="font-semibold text-xl mb-4 text-red flex items-center gap-2">
                    <span>Commission History</span>
                    <HistoryIcon />
                  </div>
                  <Paper sx={{ height: 400, width: "100%", boxShadow: 3 }}>
                    <DataGrid
                      columns={commissionColumns}
                      rows={trades as CommissionType[]}
                      {...dataGridProps}
                    />
                  </Paper>
                </>
              )}
              {depositMatch && (
                <>
                  <div className="font-semibold text-xl mb-4 text-red flex items-center gap-2">
                    <span>Deposit History</span>
                    <HistoryIcon />
                  </div>
                  <Paper sx={{ height: 400, width: "100%", boxShadow: 3 }}>
                    <DataGrid
                      columns={depositColumns}
                      rows={trades as DepositType[]}
                      {...dataGridProps}
                    />
                  </Paper>
                </>
              )}
              {withdrawMatch && (
                <>
                  <div className="font-semibold text-xl mb-4 text-red flex items-center gap-2">
                    <span>Withdraw History</span>
                    <HistoryIcon />
                  </div>
                  <Paper sx={{ height: 400, width: "100%", boxShadow: 3 }}>
                    <DataGrid
                      columns={withdrawColumns}
                      rows={trades as WithdrawType[]}
                      {...dataGridProps}
                    />
                  </Paper>
                </>
              )}
              {hierarchyMatch && (
                <>
                  <div className="text-2xl text-red font-semibold mb-5 flex gap-2 items-center">
                    <span>Hierarchy</span>
                    <GraphIcon />
                  </div>
                  <div className="p-5 border rounded-lg border-red bg-pureWhite shadow-md">
                    <div>
                      {hierarchy &&
                        Object?.values(hierarchy)?.map(
                          (h: string[], i: number) => {
                            return (
                              <div key={`level-${i}`}>
                                <div className="font-semibold">
                                  Level {i + 1}
                                </div>
                                <div className="flex gap-2 mb-4 flex-wrap">
                                  {h?.map((user: string, idx: number) => {
                                    return (
                                      <span key={`user-${idx}`} className="">
                                        {user}
                                      </span>
                                    );
                                  })}
                                </div>
                                {i !==
                                  Object?.values(hierarchy)?.length - 1 && (
                                  <hr className="border-red my-4" />
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
